
<template>
    <div>
        <Dropdown @on-click="handleMessage">
            <Badge :count="props.countMsg" :offset="[16,0]">
                <Avatar :src="props.avatarUrl" size="large"/>
            </Badge>
            <strong style="margin-left: 15px;">{{ props.userName }}</strong>
            <Icon :size="18" type="md-arrow-dropdown" />
            <template #list>
                <DropdownMenu>
                    <DropdownItem name="info">个人信息</DropdownItem>
                    <DropdownItem name="event">事件</DropdownItem>
                    <DropdownItem name="exit">退出</DropdownItem>
                </DropdownMenu>
            </template>
        </Dropdown>
    </div>
</template>

<script setup>
import {ref} from 'vue';

const emit=defineEmits(['event'])

const props=defineProps({
    userName:{
        type: String,
        default:'default'
    },
    avatarUrl:{
        type: String,
        default: ''
    },
    countMsg:{
        type: Number,
        default: 0
    }
})

//const infoAvator=ref('')
//const userName=ref('default')
//===========================================================

function handleMessage(name)
{
    //console.log(name)
    emit('event',{EventName:name})
}


</script>

<style scoped>

</style>

