import Cookies from 'js-cookie'
import config from '@/config'

const {
  title,
  cookieExpires,
  useI18n
} = config

export const TOKEN_KEY = 'token'
//export const USERID = '_token'
export const USER_INFO = '_user_info'

export const setToken = (token) => {
  //uni.setStorage
  
    Cookies.set(TOKEN_KEY, token, {
      expires: cookieExpires || 1
    })
    
  }
  
  export const getToken = () => {
    //uni.getStorage()
    
    const token = Cookies.get(TOKEN_KEY)
    if (token && token!="null") return token
    else return false
    
   //return false;
  }
  
  export const isUserLogin=()=>{
    const token=getToken()

    return !!token;
  }

export const setUserInfo = (userInfo) => {
  Cookies.set(USER_INFO,token)
}

export const getUserInfo = () =>{
  Cookies.get(USER_INFO)
}
