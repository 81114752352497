<template>
  <!--
  <div class="container">
    <div class="left" v-if="$route.meta.keepAliva">

      <Menu :theme="theme" @on-select="MenuSelect">
        <MenuSub :menus="menuList" ></MenuSub>
      </Menu>
      
    </div>
    <div class="right">
        <router-view v-slot="{Component}">
          <KeepAlive>
            <component :is="Component" :key="$route.name" v-if="$route.meta.isCache"/>
          </KeepAlive>
          <component :is="Component" :key="$route.name" v-if="!$route.meta.isCache"/>
        </router-view>

    </div>

  </div>
  -->
  <div><router-view></router-view></div>
  
</template>

<script>

  export default{
    data(){
      return {
        showNav:false,
        theme:'dark'
      }
    },
    computed:{

    },
    methods:{

    }
  }
</script>

<style lang="less">



.size{
  width: 100%;
  height: 100%;
}

html,
body{
  .size;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#app {
  .size;
}

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

</style>
